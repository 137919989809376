import React from 'react';
import config from '../../config';
import logo from "../assets/img/ob-logo-color.png" // Tell Webpack this JS file uses this image

export default function Footer() {
  return (
    <header id="header">
      <img src={logo} alt="Oil Bees Logo" />
      <h1>{config.heading}</h1>
      <p>{config.subHeading}</p>
    </header>
  );
}
