import React from 'react';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Header from '../components/Header';
import EmailForm from '../components/EmailForm';
import SlideShow from '../components/SlideShow';
import Countdown from '../components/Countdown';

var settings = {
  images: [
    { url: require('../assets/images/ob-bg01.jpg'), position: 'center' },
    { url: require('../assets/images/ob-bg02.jpg'), position: 'top' },
    { url: require('../assets/images/ob-bg03.jpg'), position: 'center' },
    { url: require('../assets/images/ob-bg04.jpg'), position: 'top' },
    { url: require('../assets/images/ob-bg05.jpg'), position: 'center' }
  ],
  // Delay.
  delay: 6000,
};

const IndexPage = () => (
  <Layout>
    <div className="page-header">
      <Header />
      <EmailForm />
      <SlideShow settings={settings} />
    </div>
    <div className="page-content">
      <Countdown />
    </div>
    <Footer />
  </Layout>
);

export default IndexPage;
