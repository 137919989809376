module.exports = {
  siteTitle: 'Oil Bees Racing', // <title>
  manifestName: 'Oil Bees',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#231f20',
  manifestThemeColor: '#f4f449',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/ob-logo-color.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  // social
  heading: 'Oil Bees Racing',
  subHeading: "Ever wanna race without the stress of being good at it? You should sign up!",
  socialLinks: [
    {
      icon: 'fa-youtube',
      name: 'Youtube',
      url: 'https://www.youtube.com/channel/UCUa5j0LkbFqbOsgmqRBZlDw',
    },
    {
      icon: 'fa-twitch',
      name: 'Twitch',
      url: 'https://www.twitch.tv/oilbees809',
    },
    {
      icon: 'fa-tumblr',
      name: 'Tumblr',
      url: 'https://oilbeesracing.tumblr.com',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:contact@oilbees.com',
    },
  ],
  events: []
};
