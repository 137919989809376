import React, { Component } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';

export class EmailForm extends Component {
  constructor() {
    super();
    this.state = { message: '', error: '', email: '' };
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    })
  };

  onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const result = await addToMailchimp(this.state.email);

    if (result.result === 'success') {
      this.setState({ message: 'Thank you!', email: '' });
    } else {
      this.setState({ error: 'Oops! Something went wrong!', email: '' });
    }
  };

  render() {
    const { message, error, email } = this.state;
    return (
      <form id="signup-form" onSubmit={this.onSubmit}>
        <input
          type="email"
          name="email"
          id="email"
          value={email}
          onChange={this.handleInputChange}
          placeholder="Email Address"
        />
        <input type="submit" value="Sign Up" />
        <span className={`${error ? 'visible failure' : ''}  message`}>
          {error}
        </span>
        <span className={`${message ? 'visible success' : ''}  message`}>
          {message}
        </span>
      </form>
    );
  }
}

export default EmailForm;
