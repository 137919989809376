import React from 'react';
import config from '../../config';
const nextEvent = config.events.length > 0 ? config.events[0] : false;

export default function Countdown() {

  let eventLink;
  if (nextEvent) {
    const daysUntilNextEvent = Math.ceil((nextEvent.date.getTime() - new Date().getTime()) / (1000*60*60*24));
    const dateOfEvent = nextEvent.date.toISOString().slice(0,10);

    eventLink = (
      <a href={nextEvent.url} >
        <div className={`fa fa-${nextEvent.icon} countdown-icon`} />
        <div className="countdown-detail">
          <h3>{daysUntilNextEvent} days until we'll be at the {nextEvent.title}!</h3>
          <p>{dateOfEvent} - {nextEvent.location}</p>
        </div>
      </a>
    );
  }

  return (
    <div className="countdown">
      {eventLink}
    </div>
  );
}
