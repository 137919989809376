import React from 'react';
import config from '../../config';

export default function Footer() {
  return (
    <footer id="footer" className="page-footer">
      <ul className="icons">
        {config.socialLinks.map(social => {
          const { icon, name, url } = social;
          return (
            <li key={icon}>
              <a href={url} className={`icon ${icon}`}>
                <span className="label">{name}</span>
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="copyright">
        <li>&copy; Oil Bees Racing</li>
        <li>All rights reserved.</li>
      </ul>
    </footer>
  );
}
